import * as Sentry from "@sentry/react";
import config from "config";
import React, { PureComponent, useCallback, useEffect } from "react";

// Turning sentry off except in QA, it's too noisy with minimized errors coming
// from outside our code base. We need to figure out what to filter and what to
// fix before we put it back in prod.
const useSentry =
  config.get("sentryEnvironment") === "qa" && config.get("sentryFrontendDSN");

class ErrorBoundary extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  componentDidCatch(error, errorInfo) {
    const { onError } = this.props;

    onError(error, errorInfo);
    this.setState({
      hasError: true,
    });
  }

  render() {
    const { children, showError } = this.props;

    const { hasError } = this.state;

    return hasError ? (
      showError ? (
        <div>
          <p>
            Minted will be back shortly. We are working hard to make our site
            available to you as soon as possible. Please check back in a few
            minutes. Have a question? Contact us at 1.888.828.6468 or
            help@minted.com.
          </p>
        </div>
      ) : null
    ) : (
      children
    );
  }
}

export const SentryBoundary = ({ children, showError = true }) => {
  // Initialize Sentry on first render
  useEffect(() => {
    if (useSentry) {
      Sentry.init({
        dsn: config.get("sentryFrontendDSN"),
        environment: `${config.get("sentryEnvironment")}-client`,
        release: config.get("deployedCommit"),
        sampleRate: parseFloat(`${config.get("sentrySampleRate")}`),
        tracesSampleRate: parseFloat(`${config.get("sentryTracesSampleRate")}`),
      });
    }
  }, []);

  const handleError = useCallback((error, errorInfo) => {
    if (useSentry) {
      Sentry.captureException(error, {
        extra: {
          errorInfo,
        },
      });
    } else {
      console.error("Error boundary: ", error, errorInfo);
    }
  }, []);

  return (
    <ErrorBoundary onError={handleError} showError={showError}>
      {children}
    </ErrorBoundary>
  );
};
