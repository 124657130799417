import { css } from "@emotion/react";
import { theme } from "@minted/minted-components";
import { bool, oneOf, shape } from "prop-types";

export const configurationPropTypes = shape({
  imageAlignment: oneOf(["top", "bottom", "left", "right"]),
  imageSize: oneOf([40, 60, 80, 100]),
  pageLayout: oneOf(["single", "multi"]),
  showMap: bool,
  textAlignment: oneOf(["left", "center", "right"]),
});

export const imageAlignmentMap = new Map([
  [
    "top",
    css`
      flex-direction: column;
    `,
  ],
  [
    "bottom",
    css`
      flex-direction: column-reverse;
    `,
  ],
  [
    "left",
    css`
      flex-direction: column;
      ${theme.media.greaterThan(theme.breakpoints.medium)(`
        flex-direction: row;
      `)}
    `,
  ],
  [
    "right",
    css`
      flex-direction: column-reverse;
      ${theme.media.greaterThan(theme.breakpoints.medium)(`
        flex-direction: row-reverse;
      `)}
    `,
  ],
]);

export const imageSizeMap = (width) => css`
  img {
    display: inline;
    max-width: ${width}%;
  }
`;

export const imageSpacingMap = new Map([
  [
    "bottom",
    css`
      margin-bottom: 20px;
    `,
  ],
  [
    "top",
    css`
      margin-top: 20px;
    `,
  ],
  [
    "left",
    css`
      flex: 0 0 50%;
      margin-bottom: 20px;

      ${theme.media.greaterThan(theme.breakpoints.medium)(`
        margin-bottom: 0;
        margin-right: 20px;
      `)}
    `,
  ],
  [
    "right",
    css`
      flex: 0 0 50%;
      margin-top: 20px;

      ${theme.media.greaterThan(theme.breakpoints.medium)(`
        margin-left: 20px;
        margin-top: 0;
      `)}
    `,
  ],
]);

export const textAlignmentMap = new Map([
  [
    "left",
    css`
      text-align: left;
    `,
  ],
  [
    "center",
    css`
      text-align: center;
    `,
  ],
  [
    "right",
    css`
      text-align: right;
    `,
  ],
]);
