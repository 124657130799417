import { scrollToElement } from "../guest-management/forms/utils";
import { makeQueryString } from "./api/utils";
import { VIDEO_LIMIT, PHOTO_LIMIT, PREVIEW_IFRAME_ID } from "./constants";
import axios from "axios";
import config from "config";
import { useCallback, useEffect, useRef } from "react";
import { v4 as uuidV4 } from "uuid";

export const hasAny = (obj, keys) => {
  for (const key of keys) {
    if (obj.hasOwnProperty(key)) {
      return true;
    }
  }

  return false;
};

export const getImageFileType = (imageUrl) => {
  const ext = imageUrl.split(".").pop().toLowerCase();

  if (ext === "png") {
    return "image/png";
  }

  return "image/jpeg";
};

export const getImageUploadExt = (file) => {
  let ext;

  switch (file.type) {
    case "image/jpeg":
      ext = ".jpg";
      break;
    case "image/png":
      ext = ".png";
      break;
    case "image/heic":
    case "image/heif":
      ext = ".heic";
      break;
    case "image/webp":
      ext = ".webp";
      break;
    case "image/gif":
      ext = ".gif";
      break;
    case "image/avif":
      ext = ".avif";
      break;
    default:
      throw new Error(
        "Only JPEG, PNG, HEIF, WebP, and AVIF images are supported.",
      );
  }

  return ext;
};

export const getImageUploadKey = (file) => {
  const ext = getImageUploadExt(file);

  return `${config.get("s3UploadKeyPrefix")}${uuidV4()}${ext}`;
};

export const fetchSignedS3Params = (params) => {
  // retrieves pre-signed parameters for uploading files to S3
  const endpoint = `${config.get("frontendScheme")}${config.get("frontendHost")}`;
  const url = `${endpoint}/sign-s3/${makeQueryString(params)}`;

  return axios(url).then((response) => response.data);
};

export const fetchSignedCloudinaryParams = (params) => {
  // retrieves pre-signed parameters for uploading files to Cloudinary
  const endpoint = `${config.get("frontendScheme")}${config.get("frontendHost")}`;
  const url = `${endpoint}/sign-cloudinary/${makeQueryString(params)}`;

  return axios(url).then((response) => response.data);
};

export const fetchUpgradePrice = () => {
  const url = `${config.get("apiScheme")}${config.get("apiDomain")}/${config.get("pricingEndpoint")}`;

  return new Promise((resolve, reject) => {
    axios(url)
      .then((response) => resolve(parseInt(response.data)))
      .catch(reject);
  });
};

export const uploadToCloudinary = (
  key,
  file,
  siteId,
  progressCallback,
  videoCount = 0,
) => {
  if (file.size > 3_000_000_000) {
    return Promise.resolve({
      error: "VideoTooLarge",
      key,
      resourceName: "videos",
    });
  }
  if (videoCount >= VIDEO_LIMIT) {
    return Promise.resolve({
      error: "VideoLimitReached",
      key,
      resourceName: "videos",
    });
  }

  return fetchSignedCloudinaryParams({
    siteId,
  })
    .then((params) => {
      const url = `https://api.cloudinary.com/v1_1/${config.get("cloudinaryCloudName")}/video/upload`;
      let currentLocation = 0;
      const bufferSize = 20_000_000; // 20 MB per chunk (cloudinary's default chunk size)
      const promises = [];
      const progress = {};

      while (currentLocation < file.size) {
        const chunk = file.slice(currentLocation, currentLocation + bufferSize);
        const chunkRange = [currentLocation, currentLocation + chunk.size - 1];
        const data = new FormData();

        data.append("file", chunk);
        data.append("api_key", config.get("cloudinaryApiKey"));
        data.append("timestamp", params.timestamp);
        data.append("signature", params.signature);
        data.append("folder", config.get("cloudinaryFolderName"));
        data.append("metadata", `site_id=${siteId}`);
        data.append("eager", "sp_full_hd_lean/m3u8|sp_full_hd_lean/mpd");
        data.append("eager_async", true);
        promises.push(
          axios(url, {
            data,
            headers: {
              "Content-Range": `bytes ${chunkRange[0]}-${chunkRange[1]}/${file.size}`,
              "X-Unique-Upload-Id": key,
            },
            method: "POST",
            onUploadProgress: (event) => {
              progress[chunkRange] = event.loaded;
              progressCallback(
                key,
                Object.values(progress).reduce((prev, cur) => prev + cur, 0) /
                  file.size,
              );
            },
          }),
        );
        currentLocation += bufferSize;
      }

      return Promise.all(promises);
    })
    .then((responses) => {
      const finalResponse =
        responses.length === 1
          ? responses[0]
          : responses.find((response) => response.data.done);

      return {
        data: finalResponse?.data,
        key,
        resourceName: "videos",
      };
    })
    .catch((error) => {
      console.error(error); // Change error handling once we start using this

      return {
        error: "UnknownError",
        key,
        resourceName: "videos",
      };
    });
};

export const uploadToS3 = (
  file,
  key,
  process = true,
  resourceName = "",
  photoCount = 0,
) => {
  // accepts a File or Blob instance an uploads it to S3 using
  // pre-signed form parameters

  // Include additional precision for tiebreaker
  const microseconds = parseInt((window.performance.now() % 1) * 1000);
  const createdAt = new Date().toISOString().slice(0, -1) + microseconds + "Z";

  if (file.size > 10_000_000) {
    return Promise.resolve({
      error: "ImageTooLarge",
      key,
      resourceName: "photos",
    });
  }
  if (photoCount >= PHOTO_LIMIT) {
    return Promise.resolve({
      error: "PhotoLimitReached",
      key,
      resourceName: "photos",
    });
  }

  if (file.size === 0) {
    console.error("File failed to upload due to being 0 bytes");

    return Promise.resolve({
      error: "ImageIsEmpty",
      key,
      resourceName: "photos",
    });
  }
  const ext = getImageUploadExt(file);

  return new Promise((resolve, reject) => {
    fetchSignedS3Params({
      ext,
      key: key || "",
      mode: process ? "process" : "",
    })
      .then((params) => {
        const data = new window.FormData();

        Object.keys(params.fields).forEach((key) => {
          data.append(key, params.fields[key]);
        });
        data.append("file", file);
        axios(params.url, {
          data,
          method: "POST",
          responseType: "text",
        })
          .then((response) => {
            const document = new window.DOMParser().parseFromString(
              response.data,
              "text/xml",
            );
            const error = document.getElementsByTagName("Error");

            if (error.length > 0) {
              reject(new Error("Images must be JPEG or PNG less than 10MB."));

              return;
            }

            resolve({
              createdAt,
              data: document,
              key: document.getElementsByTagName("Key")[0].textContent,
              resourceName,
              url: document.getElementsByTagName("Location")[0].textContent,
            });
          })
          .catch((error) => {
            console.error(error);

            resolve({
              error: "UnknownError",
              key,
              resourceName,
            });
          });
      })
      .catch((error) => {
        reject(new Error("Network error retrieving upload parameters."));
      });
  });
};

export const preventDefault = (next) => (event) => {
  if (event) {
    event.preventDefault();
  }

  next(event);
};

export const useTimeoutFn = (fn, ms) => {
  // taken from react-use
  // TODO: implement leading/trailing edge support
  const ready = useRef(false);
  const timeout = useRef();
  const callback = useRef(fn);

  const isReady = useCallback(() => ready.current, []);

  const set = useCallback(() => {
    ready.current = false;
    timeout.current && clearTimeout(timeout.current);

    timeout.current = setTimeout(() => {
      ready.current = true;
      callback.current();
    }, ms);
  }, [ms]);

  const clear = useCallback(() => {
    ready.current = null;
    timeout.current && clearTimeout(timeout.current);
  }, []);

  // update ref when function changes
  useEffect(() => {
    callback.current = fn;
  }, [fn]);

  // set on mount, clear on unmount
  useEffect(() => {
    set();

    return clear;
  }, [clear, ms, set]);

  return [isReady, clear, set];
};

export const getNewPosition = (collection) => {
  /* Returns max position + 1 based on the current collection items max
     position.
  */
  const positions = collection.items.map((item) => item.data.position);

  return Math.max(...positions, 0) + 1;
};

export const scrollToPreviewSection = (sectionId) => {
  const sectionWrapper = document
    .getElementById(PREVIEW_IFRAME_ID)
    ?.contentDocument?.getElementById(sectionId);

  if (sectionWrapper) {
    scrollToElement(sectionWrapper, {
      isInsideFrame: true,
    });
  }
};

export const getGuestTokenCookie = () =>
  ("; " + document.cookie)
    .split(`; ${config.get("guestTokenCookieName")}=`)
    .pop()
    .split(";")[0];

export const getGuestAuthSettings = () => {
  const headers = {};
  const guestToken = getGuestTokenCookie();

  // Some endpoints require guest auth, and we want to conditionally
  // append the guest token if it exists
  if (Boolean(guestToken)) {
    headers["Authorization"] = `Guest ${guestToken}`;
  }

  return { headers };
};
