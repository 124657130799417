import { DeepLinkContextProvider } from "./contexts/DeepLinkContext";
import { SiteTypeProvider } from "./contexts/SiteTypeContext";
import { TrackContextProvider } from "./contexts/TrackContext";
import API from "./core/api";
import { SentryBoundary } from "./core/components/SentryBoundary";
import { SITE_TYPES } from "./core/constants";
import { trackPageLoad } from "./core/instrumentation";
import configureStore from "./core/store";
import RefManager from "./ref-monitor/RefManager";
import RefMonitorProvider from "./ref-monitor/components/RefMonitorProvider";
import Scroller from "./scroller/Scroller";
import ScrollerProvider from "./scroller/components/ScrollerProvider";
import LoginWrapper from "./website/components/LoginWrapper";
import Website from "./website/components/Website";
import HolidayWebsite from "./website/components/holiday-website/HolidayWebsite";
import OnlineInvitationSite from "./website/components/online-invitation/OnlineInvitationSite";
import createRouter from "./website/router";
import { selectSite } from "./website/selectors";
import config from "config";
import React from "react";
import { render } from "react-dom";
import ReactGA from "react-ga";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router5";
import smoothScroll from "smoothscroll-polyfill";
import { v4 as uuidV4 } from "uuid";

smoothScroll.polyfill();

ReactGA.initialize(config.get("guestGATrackingID"));

const preloadedState = window.__PRELOADED_STATE__;
const routes = window.__ROUTES__;
const baseUrl = window.__BASE_URL__;

const deepLinkContactId = window.__ADDRESSBOOK_CONTACT_ID__;

// TODO: implement a better mechanism for ensuring API configuration
// is DRY but also can be modified at runtime. Right now, the API configuration must be
// hydrated from that which was used during server rendering to ensure any headers or other
// configuration is kept the same
const apiConfig = window.__API_CONFIG__;

API.configure(apiConfig);

const router = createRouter(routes, baseUrl, "login");
const store = configureStore(router, preloadedState);
const refManager = new RefManager();
const scroller = new Scroller(refManager);

const state = store.getState();

const startingRoute = state.router.route;

const site = selectSite(state.resources.sites);

const siteComponents = {
  [SITE_TYPES.WEDDING]: Website,
  [SITE_TYPES.HOLIDAY]: HolidayWebsite,
  [SITE_TYPES.ONLINE_INVITATION]: OnlineInvitationSite,
};
const SiteComponent = siteComponents[site.siteType];

router.start(startingRoute, (error, state) => {
  if (error) {
    console.error(error);
  }

  let guestId = localStorage.getItem("mintedWebsitesGuestId");

  if (!guestId) {
    guestId = uuidV4();

    localStorage.setItem("mintedWebsitesGuestId", guestId);
  }

  trackPageLoad(
    site.isDemoSite ? "site_demo" : "site_guest_experience",
    site,
    guestId,
  );

  render(
    <SentryBoundary>
      <Provider store={store}>
        <RouterProvider router={router}>
          <RefMonitorProvider refManager={refManager}>
            <SiteTypeProvider siteType={site.siteType}>
              <TrackContextProvider site={site}>
                <ScrollerProvider scroller={scroller}>
                  <LoginWrapper>
                    <DeepLinkContextProvider
                      addressbookContactId={deepLinkContactId}
                    >
                      <SiteComponent />
                    </DeepLinkContextProvider>
                  </LoginWrapper>
                </ScrollerProvider>
              </TrackContextProvider>
            </SiteTypeProvider>
          </RefMonitorProvider>
        </RouterProvider>
      </Provider>
    </SentryBoundary>,
    document.getElementById("root"),
  );
});
