export const UPSELL_KINDS = {
  Invitations: "INV",
  Menus: "MEN",
  PlaceCards: "PLC",
  Programs: "WPR",
  SaveTheDates: "STD",
  Signs: "WLS",
  TableNumbers: "TBL",
  ThankYouCards: "TYC",
};

export const UPSELL_TRACKING = {};

const matchingAccessoryOnly = {
  showMatchingAccessoryUntil: 0,
};

export const UPSELL_DATES = {
  [UPSELL_KINDS.SaveTheDates]: {
    showMatchingAccessoryUntil: 150,
    showUpsellAt: 360,
    showUpsellUntil: 211,
  },
  [UPSELL_KINDS.Invitations]: {
    showMatchingAccessoryUntil: 60,
    showUpsellAt: 210,
    showUpsellUntil: 91,
  },
  [UPSELL_KINDS.PlaceCards]: {
    showMatchingAccessoryUntil: 0,
    showUpsellAt: 90,
    showUpsellUntil: 1,
  },
  [UPSELL_KINDS.Menus]: matchingAccessoryOnly,
  [UPSELL_KINDS.Programs]: matchingAccessoryOnly,
  [UPSELL_KINDS.Signs]: matchingAccessoryOnly,
  [UPSELL_KINDS.ThankYouCards]: {
    showMatchingAccessoryUntil: -21,
    showUpsellAt: 0,
    showUpsellUntil: -21,
  },
};

export const MATCHING_PRODUCT_MAP = {
  [UPSELL_KINDS.Signs]: new Set([
    "AFW",
    "AWS",
    "BDS",
    "BFW",
    "BRS",
    "BWS",
    "CFW",
    "CSC",
    "CWS",
    "DFS",
    "DRS",
    "DWS",
    "EFS",
    "EWS",
    "MES",
    "RPS",
    "SCF",
    "SCH",
    "SHS",
    "WLS",
  ]),
  [UPSELL_KINDS.Programs]: new Set(["LPR", "WGP", "WPF", "WPR"]),
  [UPSELL_KINDS.PlaceCards]: new Set([
    "FBE",
    "FCH",
    "FFS",
    "FVG",
    "LPP",
    "PCB",
    "PCF",
    "PCG",
    "PCH",
    "PFH",
    "PLC",
    "PVN",
  ]),
  [UPSELL_KINDS.Menus]: new Set([
    "FLM",
    "GLM",
    "LLM",
    "LMN",
    "LMU",
    "MEN",
    "MFS",
    "MGN",
    "SME",
    "SMM",
    "VME",
  ]),
  [UPSELL_KINDS.SaveTheDates]: new Set([
    "ECC",
    "ECF",
    "ECM",
    "ECP",
    "EFM",
    "EPF",
    "FGM",
    "FPH",
    "GSM",
    "RTD",
    "SBL",
    "SFG",
    "SFM",
    "SFS",
    "SGG",
    "SGS",
    "SLE",
    "SMC",
    "SMF",
    "SMG",
    "SML",
    "SMP",
    "SNL",
    "SPC",
    "SPF",
    "STD",
    "STG",
    "SVB",
    "SVV",
  ]),
  [UPSELL_KINDS.Invitations]: new Set([
    "AIF",
    "AIG",
    "AIO",
    "CCA",
    "GIF",
    "GIV",
    "IFC",
    "IFI",
    "IFM",
    "IFS",
    "IGS",
    "IMC",
    "INI",
    "INV",
    "IPC",
    "LPI",
    "MED",
    "MEI",
    "MUE",
    "MUG",
    "MUI",
    "MUS",
    "STE",
    "VIB",
    "VIP",
    "VLI",
  ]),
  [UPSELL_KINDS.TableNumbers]: new Set([
    "TNC",
    "TBF",
    "TGN",
    "LTR",
    "WMT",
    "TBL",
  ]),
  [UPSELL_KINDS.ThankYouCards]: new Set([
    "LTY",
    "MEY",
    "MUT",
    "TCC",
    "TFF",
    "TGC",
    "TPC",
    "TYC",
    "TYF",
    "WTY",
  ]),
};
