import { SITE_TYPES } from "../core/constants";
import React, { createContext, useMemo } from "react";

export const SiteTypeContext = createContext({});

export const SiteTypeProvider = ({ children, siteType }) => {
  const providerValue = useMemo(() => {
    return {
      isHolidaySite: siteType === SITE_TYPES.HOLIDAY,
      isOnlineInvitationSite: siteType === SITE_TYPES.ONLINE_INVITATION,
      isWeddingSite: siteType === SITE_TYPES.WEDDING,
    };
  }, [siteType]);

  return (
    <SiteTypeContext.Provider value={providerValue}>
      {children}
    </SiteTypeContext.Provider>
  );
};
