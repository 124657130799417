import API from "../../core/api";
import { getGuestAuthSettings, getGuestTokenCookie } from "../../core/utils";
import { lgMarginBottomStyles } from "../../neutral/styles/spacing";
import { track } from "../../track/utils";
import { fetchGuestToken } from "../../website/actions";
import { clearCookies } from "../../website/utils";
import { css } from "@emotion/react";
import { Button, TextInput } from "@minted/minted-components";
import PropTypes from "prop-types";
import React from "react";

const style = {
  margin: "0 auto",
  maxWidth: "500px",
  padding: "0 15px 0 15px",
  position: "relative",
  textAlign: "center",
};

const smallTextStyles = {
  fontSize: "14px",
  lineHeight: "18px",
};

const blockStyles = {
  display: "block",
};

const headlineStyles = {
  fontSize: "14px",
  fontWeight: 700,
  letterSpacing: "1px",
  lineHeight: "18px",
  margin: "0 0 30px 0",
  textTransform: "uppercase",
};

const fakeLinkStyles = {
  ":active": {
    color: "#666",
  },
  ":focus": {
    color: "#666",
  },
  ":hover": {
    color: "#666",
  },
  color: "#333",
  cursor: "pointer",
  display: "inline-block",
  fontSize: "14px",
  lineHeight: "18px",
  marginBottom: "10px",
  textDecoration: "underline",
};

const errorAlertStyles = {
  backgroundColor: "#f7e0e0",
  border: "1px solid #e6c0c3",
  borderRadius: "3px",
  color: "#af4150",
  marginBottom: "30px",
  padding: "15px",
  textAlign: "left",
  width: "100%",
};

class SearchForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      candidates: null,
      showAllCandidates: false,
      value: props.initialValue,
    };
  }

  componentDidMount() {
    this.doSearch();
  }

  handleChange(event) {
    this.setState({
      value: event.target.value,
    });
  }

  handleReset() {
    this.setState({
      candidates: null,
      showAllCandidates: false,
      value: null,
    });
  }

  handleShowAllCandidates() {
    this.setState({
      showAllCandidates: true,
    });
  }

  selectGuest(guestId) {
    this.setState({
      errors: null,
      isSearching: true,
    });

    API.get(`invites/by-guest/${guestId}`, getGuestAuthSettings())
      .then((response) => {
        this.setState({
          isSearching: false,
        });
        this.props.onSelectGuest(response);
        if (response.length === 0) {
          clearCookies();
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          clearCookies();
        }
        this.setState({
          errors: error,
          isSearching: false,
        });
      });
  }

  handleSearch(event) {
    this.doSearch();
    this.forceUpdate();
  }

  doSearch() {
    if (!this.state.value) {
      return;
    }

    this.setState({
      errors: null,
      isSearching: true,
    });

    const tokenPromises = [];

    if (!Boolean(getGuestTokenCookie())) {
      tokenPromises.push(fetchGuestToken());
    }

    // If the promise exists, we need to wait for it, so .all will have either 0 or 1 promises.
    Promise.all(tokenPromises).then(() => {
      API.get("guests/search/", getGuestAuthSettings(), {
        q: this.state.value,
      })
        .then((response) => {
          this.setState({
            candidates: response,
            isSearching: false,
          });
        })
        .catch((error) => {
          this.setState({
            errors: error,
            isSearching: false,
          });
        });
      track("Find RSVP", "Find_RSVP_Clicked");
    });
  }

  renderAlternateCandidates() {
    let alternates;

    if (this.state.candidates.length > 1) {
      const candidates = this.state.candidates.slice(1).map((candidate) => (
        <div css={[smallTextStyles, blockStyles]} key={candidate.guestId}>
          <div
            css={fakeLinkStyles}
            onClick={this.selectGuest.bind(this, candidate.guestId)}
          >
            {candidate.name}
          </div>
        </div>
      ));

      alternates = (
        <div>
          <div
            css={css`
              margin: 30px 0;
            `}
          >
            {candidates}
          </div>
          <span css={smallTextStyles}>
            (Still nothing?{" "}
            <div css={fakeLinkStyles} onClick={this.handleReset.bind(this)}>
              Try another search.
            </div>
            )
          </span>
        </div>
      );
    }

    return alternates;
  }

  render() {
    const TopBar = this.props.TopBarComponent;
    let content;

    if (this.state.candidates === null || this.state.candidates.length === 0) {
      let noCandidates;

      if (this.state.candidates && this.state.candidates.length === 0) {
        noCandidates = (
          <div css={errorAlertStyles}>
            Sorry, we couldn't find your name. Please try another search.
          </div>
        );
      }

      content = (
        <div>
          {this.state.isSearching ? <TopBar shadowColor="#fff" /> : null}
          <h3 css={headlineStyles}>
            {this.props.configuration?.rsvpSubheading ||
              "Enter Your Name to RSVP"}
          </h3>
          {noCandidates}
          <TextInput
            disabled={this.state.isSearching}
            hasErrorSpacing
            label="Enter your name "
            name="invitationName"
            onChange={this.handleChange.bind(this)}
            value={this.state.value || ""}
          />
          <Button
            disabled={this.state.isSearching || !this.state.value}
            expand
            onClick={this.handleSearch.bind(this)}
            text="Find RSVP"
            type={Button.types.primary}
          />
        </div>
      );
    } else {
      if (this.state.candidates.length > 0) {
        let showAlternates;

        if (this.state.candidates.length > 1) {
          showAlternates = (
            <span css={smallTextStyles}>
              Not You?{" "}
              <div
                css={fakeLinkStyles}
                onClick={this.handleShowAllCandidates.bind(this)}
              >
                Pick a different name.
              </div>
            </span>
          );
        } else {
          showAlternates = (
            <span css={smallTextStyles}>
              <div css={fakeLinkStyles} onClick={this.handleReset.bind(this)}>
                Try another search.
              </div>
            </span>
          );
        }

        content = (
          <div>
            {this.state.isSearching ? <TopBar shadowColor="#fff" /> : null}
            <h3 css={headlineStyles}>Is this you?</h3>
            <div css={lgMarginBottomStyles}>
              <span>{this.state.candidates[0].name}</span>
            </div>
            <div css={lgMarginBottomStyles}>
              <Button
                disabled={this.state.isSearching}
                expand
                onClick={this.selectGuest.bind(
                  this,
                  this.state.candidates[0].guestId,
                )}
                submit
                text="Yes, Continue"
                type={Button.types.primary}
              />
            </div>
            <div>
              {this.state.showAllCandidates
                ? this.renderAlternateCandidates()
                : showAlternates}
            </div>
          </div>
        );
      }
    }

    return <div css={style}>{content}</div>;
  }
}

SearchForm.propTypes = {
  initialValue: PropTypes.string.isRequired,
};

export default SearchForm;
