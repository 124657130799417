import API from "../../core/api";
import { getGuestAuthSettings } from "../../core/utils";
import MemoizedMintedField from "../../forms/components/MintedField";
import { track } from "../../track/utils";
import { emailRegexp } from "../../website/validations";
import Event from "./Event";
import { css } from "@emotion/react";
import {
  Button,
  TextInput,
  Textarea,
  Select,
  RadioGroup,
} from "@minted/minted-components";
import { OneForm, FieldGroup } from "@oneform/react";
import React from "react";

const rsvpResponseStyles = {
  base: css`
    margin: 0 auto;
    max-width: 500px;
    padding: 0 15px 0 15px;
    position: relative;
    text-align: center;
  `,
  errorAlert: css`
    background-color: #f7e0e0;
    border: 1px solid #e6c0c3;
    border-radius: 3px;
    color: #af4150;
    margin-bottom: 30px;
    padding: 15px;
    text-align: left;
  `,
  headline: css`
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 18px;
    margin: 0;
    text-transform: uppercase;
  `,
  label: css`
    display: block;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 10px;
  `,
  largeText: css`
    display: block;
    font-size: 20px;
    line-height: 28px;
    margin: 0 0 20px 0;
  `,
  panel: css`
    border: 1px solid #ccc;
  `,
  panelSection: css`
    border-bottom: 1px solid #eee;
    display: block;
    padding: 20px;
  `,
  partySelect: css`
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 120px;
  `,
  preventIphoneZoom: css`
    input,
    textarea {
      font-size: 16px;
    }
  `,
  radioButton: css`
    display: flex;
    flex-direction: row;
    justify-content: center;
  `,
  shaded: css`
    background-color: #f9f9f9;
  `,
  smallText: css`
    font-size: 14px;
    line-height: 18px;
  `,
};

const groupValidations = [
  {
    fieldNames: ["Response.attendingEvent", "Response.partySelect"],
    getErrorMessages: ({ values }) => {
      const attending = values["Response.attendingEvent"].some(
        (res) => res.value === "true",
      );

      // If they're attending even one event they need to specify party size
      if (attending && !values["Response.partySelect"]) {
        return {
          "Response.partySelect": "Required",
        };
      }
    },
  },
  {
    fieldNames: ["Response.questionAnswer", "Response.attendingEvent"],
    getErrorMessages: ({ values }) => {
      const errors = {};

      values["Response.questionAnswer"].forEach((answer) => {
        const answerName = answer.name;
        const eventId = answerName.substring(
          answerName.indexOf(":") + 1,
          answerName.indexOf("-"),
        );
        const attendingEvent = values["Response.attendingEvent"].find(
          (res) =>
            res.name === `Response.attendingEvent/Response.eventId:${eventId}`,
        )?.value;

        // If they're attending an event they have to answer questions for that event
        if (attendingEvent === "true" && !answer.value?.trim()) {
          errors[answerName] = "Required if attending";
        }
      });

      return errors;
    },
  },
];

const validations = {
  // Email is not required, but if it's valued it must be valid
  "Response.contactEmail": [
    {
      errorMessage: "Email is invalid",
      getIsValid: ({ value }) => !value || (value && emailRegexp.test(value)),
    },
  ],
};

export default class Response extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      errors: null,
      isSubmitting: false,
    };
    this.rsvpCacheKey = `rsvpFormData-${props.invites[0].guest.id}`;
  }

  scrollToTop() {
    // Scrolling to the top of the modal to make all possible errors visible
    this.wrapper.scrollIntoView({
      behavior: "smooth",
    });
  }

  handleOnSubmit(values) {
    window.sessionStorage.setItem(
      this.rsvpCacheKey,
      JSON.stringify(values.registeredValues),
    );
    this.setState({
      errors: null,
      isSubmitting: true,
    });

    const rsvps = [];
    const eventIds = [];
    const questionIdsForEventIds = {};

    this.props.invites.forEach((invite) => {
      eventIds.push(invite.event.id);
      if (invite.event.questions?.length > 0) {
        questionIdsForEventIds[invite.event.id] = invite.event.questions.map(
          (question) => question.id,
        );
      }
    });

    eventIds.forEach((eventId) => {
      // Get question IDs for event
      const answers = [];

      const invite = this.props.invites.find(
        (invite) => invite.event.id === eventId,
      );

      if (questionIdsForEventIds[eventId]) {
        questionIdsForEventIds[eventId].forEach((questionId) => {
          const text =
            values.registeredValues[
              `Response.questionAnswer/Response.eventAndQuestionId:${eventId}-${questionId}`
            ];

          // Only add answers that have values, otherwise the backend says text is required
          if (text) {
            answers.push({
              question: questionId,
              text,
            });
          }
        });
      }

      rsvps.push({
        answers,
        attending:
          values.registeredValues[
            `Response.attendingEvent/Response.eventId:${eventId}`
          ],
        event: eventId,
        invite: invite && invite.id,
        source: "web",
      });
    });

    // If user is attending at least one event party size must be filled out
    const attending = rsvps.some((rsvp) => rsvp.attending === "true");
    let partySize = values.registeredValues["Response.partySelect"];

    if (!partySize && !attending) {
      // Not attending, set party size to their max party or 1 if not given restriction
      partySize = this.props.invites[0].guest.maxParty ?? 1;
    }

    const payload = {
      guest: {
        contactEmail: values.registeredValues["Response.contactEmail"] ?? "",
        id: this.props.invites[0].guest.id,
        party: partySize,
      },
      rsvps,
    };

    API.post("rsvps/for_guest", getGuestAuthSettings(), payload)
      .then((response) => {
        if (!response || response.length === 0) {
          throw new Error(
            `No responses found for guest ${this.props.invites[0].guest.id}`,
          );
        }
        this.wrapper.scrollIntoView({
          behavior: "smooth",
        });
        this.setState({
          isSubmitting: false,
        });
        this.props.onGuestResponded(response);
      })
      .catch((error) => {
        this.wrapper.scrollIntoView({
          behavior: "smooth",
        });
        this.setState({
          errors: error.response.body,
          isSubmitting: false,
        });
      });

    track("Submit RSVP", "Submit_RSVP_Clicked");
  }

  renderEventSummary() {
    return (
      <div
        css={css`
          margin-bottom: 30px;
        `}
      >
        <div css={rsvpResponseStyles.panel}>
          <div css={rsvpResponseStyles.panelSection}>
            <h3 css={rsvpResponseStyles.headline}>Event Summary</h3>
          </div>

          <div css={rsvpResponseStyles.panelSection}>
            <p>
              Get a summary of these events and a day-of reminder email
              delivered to your inbox.
            </p>
            <div css={rsvpResponseStyles.preventIphoneZoom}>
              <MemoizedMintedField>
                <TextInput
                  disabled={this.state.isSubmitting}
                  hasErrorSpacing
                  label="Email Address"
                  name="Response.contactEmail"
                  value=""
                />
              </MemoizedMintedField>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderInvite(invite) {
    const TopBar = this.props.TopBarComponent;

    let questions;

    if (invite.event.questions.length > 0) {
      questions = invite.event.questions.map((question, index) => {
        if (invite.event.questions.length - 1 !== index) {
          rsvpResponseStyles.marginBottom = "0";
        }

        return (
          <div
            css={css`
              margin-top: 20px;
            `}
            key={question.id}
          >
            <span css={rsvpResponseStyles.smallText}>{question.text}</span>
            <FieldGroup
              id={`${invite.event.id}-${question.id}`}
              name="Response.eventAndQuestionId"
            >
              <div css={rsvpResponseStyles.preventIphoneZoom}>
                <MemoizedMintedField>
                  <Textarea
                    disabled={this.state.isSubmitting}
                    hasErrorSpacing
                    name="Response.questionAnswer"
                    value=""
                  />
                </MemoizedMintedField>
              </div>
            </FieldGroup>
          </div>
        );
      });
    }

    return (
      <div css={rsvpResponseStyles.panel}>
        {this.state.isSubmitting ? <TopBar shadowColor="#fff" /> : null}
        <div css={rsvpResponseStyles.panelSection}>
          <h3 css={rsvpResponseStyles.headline}>{invite.event.title}</h3>
        </div>
        <div css={rsvpResponseStyles.panelSection}>
          <div css={rsvpResponseStyles.radioButton}>
            <div>
              <span
                css={css`
                  margin-right: 10px;
                `}
              >
                Attending:
              </span>
            </div>
            <div>
              <FieldGroup id={String(invite.event.id)} name="Response.eventId">
                <MemoizedMintedField>
                  <RadioGroup
                    disabled={this.state.isSubmitting}
                    horizontal={true}
                    name="Response.attendingEvent"
                    onChange={() => {}}
                    options={[
                      {
                        label: "Yes",
                        value: "true",
                      },
                      {
                        label: "No",
                        value: "false",
                      },
                    ]}
                    value=""
                  />
                </MemoizedMintedField>
              </FieldGroup>
            </div>
          </div>
          {questions}
        </div>
        <div css={[rsvpResponseStyles.panelSection, rsvpResponseStyles.shaded]}>
          <Event event={invite.event} />
        </div>
      </div>
    );
  }

  render() {
    const { invites } = this.props;
    const TopBar = this.props.TopBarComponent;

    const maxParty =
      invites[0].guest.maxParty === 0 ? null : invites[0].guest.maxParty;

    const partyOptions = Array.from(Array(maxParty ?? 9).keys()).map(
      (option) => ({
        text: `${option + 1}`,
        value: option + 1,
      }),
    );

    if (!maxParty) {
      partyOptions.push({
        text: "10+",
        value: 10,
      });
    }

    let errors;

    if (this.state.errors && this.state.errors.rsvps) {
      const errorList = this.state.errors.rsvps
        .map((error) =>
          Object.entries(error).map(([fieldName, errorText], index) => (
            <div key={index}>
              {typeof errorText === "string"
                ? errorText
                : "Please contact Minted customer support"}
            </div>
          )),
        )
        .flat();

      errors = (
        <div css={rsvpResponseStyles.errorAlert}>
          <strong>Oops. It looks like you missed something.</strong>
          {errorList}
        </div>
      );
    }

    const responses = (invites || []).map((invite) => (
      <div
        css={css`
          margin-bottom: 30px;
        `}
        key={invite.id}
      >
        {this.renderInvite(invite)}
      </div>
    ));

    const eventSummary = this.renderEventSummary();
    const defaultValues = window.sessionStorage[this.rsvpCacheKey]
      ? JSON.parse(window.sessionStorage[this.rsvpCacheKey])
      : {};

    this.props.invites.forEach((invite) => {
      const attendingResponse =
        defaultValues[
          `Response.attendingEvent/Response.eventId:${invite.event.id}`
        ];

      if (!attendingResponse) {
        defaultValues[
          `Response.attendingEvent/Response.eventId:${invite.event.id}`
        ] = "true";
      }
    });

    return (
      <div
        css={rsvpResponseStyles.base}
        ref={(el) => {
          this.wrapper = el;
        }}
      >
        {this.state.isSubmitting ? <TopBar shadowColor="#fff" /> : null}
        <div>{errors}</div>
        {/* Documentation on how to use OneForm: https://docs.oneform.dev/ */}
        <OneForm
          groupValidations={groupValidations}
          onSubmit={this.handleOnSubmit.bind(this)}
          validations={validations}
          values={defaultValues}
        >
          <span css={rsvpResponseStyles.largeText}>
            RSVP for {invites[0].guest.contact.name}
          </span>
          <label css={rsvpResponseStyles.label}>Number in Party</label>
          <div css={rsvpResponseStyles.partySelect}>
            <MemoizedMintedField>
              <Select
                disabled={this.state.isSubmitting}
                hasErrorSpacing
                name="Response.partySelect"
                options={partyOptions}
                placeholder="Select"
              />
            </MemoizedMintedField>
          </div>
          <div>{responses}</div>
          {eventSummary}
          <Button
            disabled={this.state.isSubmitting}
            expand
            onClick={this.scrollToTop.bind(this)}
            submit
            text="Submit RSVP"
            type={Button.types.primary}
          />
        </OneForm>
      </div>
    );
  }
}
