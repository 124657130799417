import RefSource from "../../ref-monitor/RefSource";
import { selectCollection } from "../../resources/selectors";
import ContentSection from "./ContentSection";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { connect } from "react-redux";

const propTypes = {
  pageId: PropTypes.number,
  sections: PropTypes.arrayOf(PropTypes.object),
};

const ContentSections = ({ isMobileView, sections }) => (
  <Fragment>
    {sections.map((section) => (
      <ContentSection
        isMobileView={isMobileView}
        key={section.id || "new"}
        section={section}
      />
    ))}
  </Fragment>
);

ContentSections.propTypes = propTypes;

const mapStateToProps = (state, props) => {
  const { pageId } = props;

  const page =
    pageId || selectCollection(state.resources.pages).items[0].data.id;

  const collection = selectCollection(state.resources.sections, {
    page,
  });

  const sections = [
    ...collection.items,
    ...collection.resource.staged.create,
  ].map((section) => ({
    ...section.data,
    description: section.data.content,
  }));

  return {
    sections,
  };
};

export default RefSource(connect(mapStateToProps)(ContentSections));
