import OnlineInvitationRSVPForm from "../../../guest-management/forms/RSVPForm/OnlineInvitations/OnlineInvitationRSVPForm";
import RSVPSearchModal from "../../../guest-management/forms/RSVPSearchModal/RSVPSearchModal";
import { css } from "@emotion/react";
import { Button } from "@minted/fancyclothes";
import {
  Modal,
  ModalBody,
  ModalHeader,
  styleUtils,
  theme,
} from "@minted/minted-components";
import React, { useCallback, useState } from "react";

const rsvpStyles = {
  buttonWrapper: css`
    margin-left: ${theme.spacing.x4};
    margin-right: ${theme.spacing.x4};
    position: fixed;
    bottom: ${theme.spacing.x5};
    z-index: 1000;
    button {
      width: ${styleUtils.rem(112)};
    }
    ${theme.media.greaterThan(styleUtils.rem(768))`
      button {
        width: ${styleUtils.rem(200)};
        font-size: 24px;
      }
    `};
  `,
  modalHeaderText: css`
    color: ${theme.colors.black};
  `,
};

const OnlineInvitationRSVPButton = ({ event, site }) => {
  const [isRSVPFormOpen, setIsRSVPFormOpen] = useState(false);
  const [isRSVPSearchFormOpen, setIsRSVPSearchFormOpen] = useState(false);
  const [rsvpData, setRSVPData] = useState(null);

  const setRsvpModalData = useCallback((rsvpData) => {
    setRSVPData(rsvpData);
    setIsRSVPSearchFormOpen(false);
    setIsRSVPFormOpen(true);
  }, []);

  const exitForm = useCallback(() => {
    setIsRSVPFormOpen(false);
    setRSVPData(null);
  }, []);

  return (
    <>
      <div css={rsvpStyles.buttonWrapper}>
        <Button
          floating
          onClick={() => setIsRSVPSearchFormOpen(true)}
          text="RSVP"
          xPosition="left"
          yPosition="bottom"
        />
      </div>
      {isRSVPSearchFormOpen && (
        <RSVPSearchModal
          closeModal={() => setIsRSVPSearchFormOpen(false)}
          initialSearchTerm=""
          openInvitation={event.openInvitation}
          setRsvpData={setRsvpModalData}
          site={site}
        />
      )}

      {isRSVPFormOpen && rsvpData && (
        <Modal mobileFullscreen onCloseClick={exitForm} open size="medium">
          <ModalHeader>
            <div css={rsvpStyles.modalHeaderText}>RSVP</div>
          </ModalHeader>
          <ModalBody>
            <OnlineInvitationRSVPForm
              contact={rsvpData.contact}
              event={rsvpData.events[0]}
              guest={rsvpData.guests[0]}
              siteId={site.id}
            />
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default OnlineInvitationRSVPButton;
