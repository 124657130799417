import { contentConfiguration, ContentPage, contentUtil } from "../../content";
import { track } from "../../track/utils";
import { arrayOf, object, string } from "prop-types";
import React, { useCallback } from "react";

const Travel = ({ accommodations, configuration, id, pageData, title }) => {
  const actionLinkHandler = useCallback(
    () => track("View Travel & Accommodations", "Travel_Visit_URL_clicked"),
    [],
  );

  return (
    <ContentPage
      actionLinkProps={{
        onClick: actionLinkHandler,
        text: "Visit Website",
      }}
      configuration={configuration}
      data={accommodations}
      id={id}
      pageData={pageData}
      title={title}
    />
  );
};

Travel.propTypes = {
  accommodations: arrayOf(contentUtil.contentPropTypes).isRequired,
  configuration: contentConfiguration.configurationPropTypes,
  id: string.isRequired,
  pageData: object,
  title: string.isRequired,
};

export default Travel;
