import { Global } from "@emotion/react";
import { DesignComponent } from "@minted/fancyclothes";
import React from "react";

export default class Footer extends DesignComponent {
  render() {
    const { artist } = this.props;

    const styles = {
      artist: {
        color: this.getRule("fontColor", "DesignProvider"),
        fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "9px",
        fontWeight: "400",
        marginLeft: "5px",
        marginTop: "-0.5px",
        textAlign: "center",
        textOverflow: "ellipsis",
      },
      artistContainer: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        paddingTop: "5px",
      },
      innerWrapper: {
        margin: "0 auto",
        paddingBottom: "30px",
        textAlign: "center",
        width: "100%",
      },
      logo: {
        display: "block",
        margin: "0 auto",
        width: "50px",
      },
      mintedLink: {
        textDecoration: "none",
      },
      svg: {
        fill: this.getRule("fontColor", "DesignProvider"),
      },
      wrapper: {
        paddingTop: "30px",
      },
    };

    const svgSelector = ["path", "rect", "polygon", "circle", "ellipse"]
      .map((el) => `.mintedArtistFooter ${el}`)
      .join();

    return (
      <div css={styles.wrapper}>
        <Global
          styles={{
            [svgSelector]: styles.svg,
          }}
        />

        <div className="mintedArtistFooter" css={styles.innerWrapper}>
          <a
            css={styles.mintedLink}
            href="https://www.minted.com/?utm_source=customizable-guest-site"
            rel="noreferrer"
            target="_blank"
          >
            <svg
              alt="Minted: Art, Home, Stationery, Wedding"
              css={styles.logo}
              height="16px"
              version="1.1"
              width="50px"
              x="0px"
              xmlns="http://www.w3.org/2000/svg"
              y="0px"
            >
              <path d="M0 3.2h1.3v1.3h0c0.6-1 1.6-1.4 2.8-1.4c1 0 2 0.4 2.3 1.4C7 3.6 8 3.1 9.1 3.1c1.7 0 2.8 0.7 2.8 2.5 v6.2h-1.4V6.2c0-1.1-0.3-1.9-1.7-1.9c-1.3 0-2.2 0.9-2.2 2.2v5.3H5.3V6.2c0-1.1-0.3-1.9-1.6-1.9C2 4.3 1.4 5.8 1.4 6.5v5.3H0V3.2z M13.8 11.8h1.4V3.2h-1.4V11.8z M17 11.8h1.4V7c0-1.5 0.8-2.7 2.5-2.7C22 4.3 22.7 5 22.7 6v5.8h1.4V6.2c0-1.8-0.7-3.1-2.9-3.1 c-1.2 0-2.2 0.5-2.8 1.6h0V3.2H17V11.8z M27.5 0.7h-1.4v2.6h-1.4v1.2h1.4v5.4c0 1.6 0.6 1.9 2 1.9h1.1v-1.2h-0.6 c-0.9 0-1.1-0.1-1.1-0.8V4.5h1.7V3.2h-1.7V0.7z M36 9.1c-0.2 1.1-1.1 1.7-2.2 1.7c-1.9 0-2.7-1.3-2.7-2.8h6.4 c0.1-2.1-0.8-4.9-3.9-4.9c-2.3 0-4 1.9-4 4.4c0.1 2.6 1.4 4.5 4.1 4.5c1.9 0 3.3-1 3.7-2.9H36z M31.2 6.7c0.1-1.3 1-2.4 2.4-2.4 c1.4 0 2.4 1.1 2.5 2.4H31.2z M46.4 0H45v4.4h0c-0.6-1-1.9-1.3-2.8-1.3c-2.6 0-3.9 2-3.9 4.4c0 2.4 1.3 4.5 3.9 4.5 c1.2 0 2.3-0.4 2.7-1.3h0v1.1h1.4V0z M42.5 10.7c-1.8 0-2.6-1.5-2.6-3.1c0-1.7 0.6-3.3 2.6-3.3c1.9 0 2.7 1.6 2.7 3.2 C45.1 9.1 44.4 10.7 42.5 10.7 M15.2 0.3h-1.4v1.4h1.4V0.3z M49.4 10.4H48v1.4h1.4V10.4z" />
            </svg>

            <div css={styles.artistContainer}>
              <div>
                <svg
                  height="13px"
                  version="1.1"
                  width={artist ? "216px" : "175px"}
                  x="0px"
                  xmlns="http://www.w3.org/2000/svg"
                  y="0px"
                >
                  <path d="M1 9.9l2.8-6.1c0-0.1 0.1-0.1 0.2-0.1H4c0 0 0.1 0 0.2 0.1l2.7 6.1c0.1 0.1 0 0.2-0.2 0.2H6.2C6.1 10.2 6 10.1 6 10L5.4 8.8 H2.5C2.3 9.2 2.1 9.6 2 10c0 0.1-0.1 0.1-0.2 0.1H1.2C1 10.2 1 10 1 9.9z M5.1 8L4 5.5h0L2.9 8H5.1z" />
                  <path d="M8.1 4c0-0.1 0.1-0.2 0.2-0.2h2.4c1.1 0 2 0.9 2 1.9c0 0.8-0.5 1.5-1.3 1.8l1.2 2.3c0.1 0.1 0 0.3-0.2 0.3h-0.7 c-0.1 0-0.1 0-0.1-0.1l-1.2-2.4H9V10c0 0.1-0.1 0.2-0.2 0.2H8.3c-0.1 0-0.2-0.1-0.2-0.2V4z M10.6 6.9c0.6 0 1.1-0.5 1.1-1.1 c0-0.6-0.5-1.1-1.1-1.1H9v2.2H10.6z" />
                  <path d="M15.2 4.7h-1.5c-0.1 0-0.2-0.1-0.2-0.2V4c0-0.1 0.1-0.2 0.2-0.2h3.9c0.1 0 0.2 0.1 0.2 0.2v0.5c0 0.1-0.1 0.2-0.2 0.2h-1.5 V10c0 0.1-0.1 0.2-0.2 0.2h-0.6c-0.1 0-0.2-0.1-0.2-0.2V4.7z" />
                  <path d="M27.2 4c0-0.1 0.1-0.2 0.2-0.2H28c0.1 0 0.2 0.1 0.2 0.2v2.5h3.3V4c0-0.1 0.1-0.2 0.2-0.2h0.6c0.1 0 0.2 0.1 0.2 0.2v6 c0 0.1-0.1 0.2-0.2 0.2h-0.6c-0.1 0-0.2-0.1-0.2-0.2V7.4h-3.3V10c0 0.1-0.1 0.2-0.2 0.2h-0.6c-0.1 0-0.2-0.1-0.2-0.2V4z" />
                  <path d="M37 3.8c1.8 0 3.2 1.4 3.2 3.2c0 1.8-1.4 3.2-3.2 3.2c-1.8 0-3.2-1.4-3.2-3.2C33.8 5.2 35.2 3.8 37 3.8z M37 9.3 c1.3 0 2.3-1 2.3-2.3c0-1.3-1.1-2.3-2.3-2.3c-1.3 0-2.3 1.1-2.3 2.3C34.7 8.3 35.7 9.3 37 9.3z" />
                  <path d="M42.3 3.9c0-0.1 0.1-0.1 0.2-0.1h0.1c0.1 0 0.1 0 0.2 0.1l1.9 4.6h0l1.9-4.6c0-0.1 0.1-0.1 0.2-0.1h0.1 c0.1 0 0.1 0.1 0.2 0.1l1.1 6c0 0.1 0 0.2-0.2 0.2h-0.6c-0.1 0-0.2-0.1-0.2-0.1l-0.7-4c0 0 0 0 0 0l-1.6 4.1c0 0.1-0.1 0.1-0.2 0.1 h-0.2c-0.1 0-0.1 0-0.2-0.1L42.8 6c0 0 0 0 0 0l-0.6 4c0 0.1-0.1 0.1-0.2 0.1h-0.6c-0.1 0-0.2-0.1-0.2-0.2L42.3 3.9z" />
                  <path d="M49.7 4c0-0.1 0.1-0.2 0.2-0.2h3.6c0.1 0 0.2 0.1 0.2 0.2v0.5c0 0.1-0.1 0.2-0.2 0.2h-2.8v1.8h2.4c0.1 0 0.2 0.1 0.2 0.2 v0.5c0 0.1-0.1 0.2-0.2 0.2h-2.4v1.9h2.8c0.1 0 0.2 0.1 0.2 0.2V10c0 0.1-0.1 0.2-0.2 0.2h-3.6c-0.1 0-0.2-0.1-0.2-0.2V4z" />
                  <path d="M64.4 9.2c0.1-0.1 0.2-0.3 0.2-0.4s0.2-0.2 0.3-0.1c0.1 0 0.7 0.6 1.4 0.6c0.6 0 1-0.4 1-0.8c0-0.5-0.5-0.9-1.4-1.3 c-0.9-0.4-1.6-0.9-1.6-1.9c0-0.7 0.5-1.7 2-1.7c0.9 0 1.6 0.5 1.7 0.5c0.1 0 0.1 0.2 0.1 0.3c-0.1 0.1-0.2 0.2-0.2 0.3 c-0.1 0.1-0.2 0.2-0.3 0.1c-0.1 0-0.7-0.5-1.2-0.5c-0.8 0-1 0.5-1 0.8c0 0.5 0.4 0.8 1.1 1.1c1 0.4 1.9 0.9 1.9 2 c0 0.9-0.8 1.7-2 1.7c-1.1 0-1.8-0.6-1.9-0.7C64.4 9.5 64.3 9.4 64.4 9.2z" />
                  <path d="M70.8 4.7h-1.5c-0.1 0-0.2-0.1-0.2-0.2V4c0-0.1 0.1-0.2 0.2-0.2h3.9c0.1 0 0.2 0.1 0.2 0.2v0.5c0 0.1-0.1 0.2-0.2 0.2h-1.5 V10c0 0.1-0.1 0.2-0.2 0.2h-0.6c-0.1 0-0.2-0.1-0.2-0.2V4.7z" />
                  <path d="M73.4 9.9l2.8-6.1c0-0.1 0.1-0.1 0.2-0.1h0.1c0 0 0.1 0 0.2 0.1l2.7 6.1c0.1 0.1 0 0.2-0.2 0.2h-0.6c-0.1 0-0.2-0.1-0.2-0.1 l-0.6-1.2h-2.9c-0.2 0.4-0.4 0.8-0.5 1.2c0 0.1-0.1 0.1-0.2 0.1h-0.6C73.4 10.2 73.4 10 73.4 9.9z M77.5 8l-1.1-2.5h0L75.2 8H77.5z" />
                  <path d="M81 4.7h-1.5c-0.1 0-0.2-0.1-0.2-0.2V4c0-0.1 0.1-0.2 0.2-0.2h3.9c0.1 0 0.2 0.1 0.2 0.2v0.5c0 0.1-0.1 0.2-0.2 0.2h-1.5V10 c0 0.1-0.1 0.2-0.2 0.2h-0.6c-0.1 0-0.2-0.1-0.2-0.2V4.7z" />
                  <path d="M84.6 4c0-0.1 0.1-0.2 0.2-0.2h0.6c0.1 0 0.2 0.1 0.2 0.2v6c0 0.1-0.1 0.2-0.2 0.2h-0.6c-0.1 0-0.2-0.1-0.2-0.2V4z" />
                  <path d="M90.2 3.8c1.8 0 3.2 1.4 3.2 3.2c0 1.8-1.4 3.2-3.2 3.2C88.4 10.2 87 8.8 87 7C87 5.2 88.4 3.8 90.2 3.8z M90.2 9.3 c1.3 0 2.3-1 2.3-2.3c0-1.3-1.1-2.3-2.3-2.3c-1.3 0-2.3 1.1-2.3 2.3C87.9 8.3 88.9 9.3 90.2 9.3z" />
                  <path d="M94.8 3.9c0-0.1 0.1-0.2 0.2-0.2h0.2l3.9 4.5h0V4c0-0.1 0.1-0.2 0.2-0.2h0.6c0.1 0 0.2 0.1 0.2 0.2v6.1 c0 0.1-0.1 0.2-0.2 0.2h-0.2l-3.9-4.6h0V10c0 0.1-0.1 0.2-0.2 0.2H95c-0.1 0-0.2-0.1-0.2-0.2V3.9z" />
                  <path d="M102.1 4c0-0.1 0.1-0.2 0.2-0.2h3.6c0.1 0 0.2 0.1 0.2 0.2v0.5c0 0.1-0.1 0.2-0.2 0.2H103v1.8h2.4c0.1 0 0.2 0.1 0.2 0.2 v0.5c0 0.1-0.1 0.2-0.2 0.2H103v1.9h2.8c0.1 0 0.2 0.1 0.2 0.2V10c0 0.1-0.1 0.2-0.2 0.2h-3.6c-0.1 0-0.2-0.1-0.2-0.2V4z" />
                  <path d="M107.6 4c0-0.1 0.1-0.2 0.2-0.2h2.4c1.1 0 2 0.9 2 1.9c0 0.8-0.5 1.5-1.3 1.8l1.2 2.3c0.1 0.1 0 0.3-0.2 0.3h-0.7 c-0.1 0-0.1 0-0.1-0.1l-1.2-2.4h-1.4V10c0 0.1-0.1 0.2-0.2 0.2h-0.6c-0.1 0-0.2-0.1-0.2-0.2V4z M110.2 6.9c0.6 0 1.1-0.5 1.1-1.1 c0-0.6-0.5-1.1-1.1-1.1h-1.6v2.2H110.2z" />
                  <path d="M115.1 7.1l-2.1-3c-0.1-0.1 0-0.3 0.1-0.3h0.7c0.1 0 0.1 0 0.1 0.1l1.6 2.3l1.6-2.3c0 0 0.1-0.1 0.1-0.1h0.7 c0.1 0 0.2 0.1 0.1 0.3l-2.1 3V10c0 0.1-0.1 0.2-0.2 0.2h-0.6c-0.1 0-0.2-0.1-0.2-0.2V7.1z" />
                  <path d="M125.5 4.1c0-0.1 0-0.2 0.2-0.2h0.6c0.1 0 0.1 0.1 0.2 0.1l1.1 4.1h0l1.5-4.2c0-0.1 0.1-0.1 0.2-0.1h0.2 c0.1 0 0.1 0 0.2 0.1l1.5 4.2h0l1.1-4.1c0-0.1 0.1-0.1 0.2-0.1h0.6c0.1 0 0.2 0.1 0.2 0.2l-1.7 6c0 0.1-0.1 0.1-0.2 0.1H131 c-0.1 0-0.1 0-0.2-0.1l-1.6-4.5h0l-1.6 4.5c0 0.1-0.1 0.1-0.2 0.1h-0.1c-0.1 0-0.1-0.1-0.2-0.1L125.5 4.1z" />
                  <path d="M134.4 4c0-0.1 0.1-0.2 0.2-0.2h3.6c0.1 0 0.2 0.1 0.2 0.2v0.5c0 0.1-0.1 0.2-0.2 0.2h-2.8v1.8h2.4c0.1 0 0.2 0.1 0.2 0.2 v0.5c0 0.1-0.1 0.2-0.2 0.2h-2.4v1.9h2.8c0.1 0 0.2 0.1 0.2 0.2V10c0 0.1-0.1 0.2-0.2 0.2h-3.6c-0.1 0-0.2-0.1-0.2-0.2V4z" />
                  <path d="M140 4c0-0.1 0.1-0.2 0.2-0.2h2.1c1.7 0 3.2 1.4 3.2 3.1c0 1.7-1.4 3.2-3.2 3.2h-2.1c-0.1 0-0.2-0.1-0.2-0.2V4z M142.1 9.3 c1.3 0 2.3-1 2.3-2.3c0-1.3-1-2.3-2.3-2.3h-1.2v4.6H142.1z" />
                  <path d="M146.9 4c0-0.1 0.1-0.2 0.2-0.2h2.1c1.7 0 3.2 1.4 3.2 3.1c0 1.7-1.4 3.2-3.2 3.2h-2.1c-0.1 0-0.2-0.1-0.2-0.2V4z M149 9.3 c1.3 0 2.3-1 2.3-2.3c0-1.3-1-2.3-2.3-2.3h-1.2v4.6H149z" />
                  <path d="M153.9 4c0-0.1 0.1-0.2 0.2-0.2h0.6c0.1 0 0.2 0.1 0.2 0.2v6c0 0.1-0.1 0.2-0.2 0.2h-0.6c-0.1 0-0.2-0.1-0.2-0.2V4z" />
                  <path d="M156.8 3.9c0-0.1 0.1-0.2 0.2-0.2h0.2l3.9 4.5h0V4c0-0.1 0.1-0.2 0.2-0.2h0.6c0.1 0 0.2 0.1 0.2 0.2v6.1 c0 0.1-0.1 0.2-0.2 0.2h-0.2l-3.9-4.6h0V10c0 0.1-0.1 0.2-0.2 0.2H157c-0.1 0-0.2-0.1-0.2-0.2V3.9z" />
                  <path d="M166.9 3.8c0.9 0 1.6 0.3 2.2 0.8c0.1 0.1 0.1 0.2 0 0.3c-0.1 0.1-0.3 0.3-0.4 0.4c-0.1 0.1-0.1 0.1-0.2 0 c-0.4-0.4-1-0.6-1.5-0.6c-1.3 0-2.3 1.1-2.3 2.3c0 1.3 1 2.3 2.3 2.3c0.7 0 1.3-0.3 1.4-0.3V8h-0.9c-0.1 0-0.2-0.1-0.2-0.2V7.3 c0-0.1 0.1-0.2 0.2-0.2h1.6c0.1 0 0.2 0.1 0.2 0.2c0 0.7 0 1.5 0 2.2c0 0 0 0.1-0.1 0.1c0 0-0.9 0.6-2.2 0.6c-1.8 0-3.2-1.4-3.2-3.2 C163.6 5.2 165.1 3.8 166.9 3.8z" />
                  {/* Conditionally render Art by section of footer if artist exists */}
                  {artist && (
                    <>
                      <rect height="13" width="0.5" x="177.4" y="0" />
                      <path d="M185.1 9.9l2.8-6.1c0-0.1 0.1-0.1 0.2-0.1h0.1c0 0 0.1 0 0.2 0.1l2.7 6.1c0.1 0.1 0 0.2-0.2 0.2h-0.6 c-0.1 0-0.2-0.1-0.2-0.1l-0.6-1.2h-2.9c-0.2 0.4-0.4 0.8-0.5 1.2c0 0.1-0.1 0.1-0.2 0.1h-0.6C185.2 10.2 185.1 10 185.1 9.9z M189.2 8l-1.1-2.5h0L187 8H189.2z" />
                      <path d="M192.2 4c0-0.1 0.1-0.2 0.2-0.2h2.4c1.1 0 2 0.9 2 1.9c0 0.8-0.5 1.5-1.3 1.8l1.2 2.3c0.1 0.1 0 0.3-0.2 0.3h-0.7 c-0.1 0-0.1 0-0.1-0.1l-1.2-2.4h-1.4V10c0 0.1-0.1 0.2-0.2 0.2h-0.6c-0.1 0-0.2-0.1-0.2-0.2V4z M194.8 6.9c0.6 0 1.1-0.5 1.1-1.1 c0-0.6-0.5-1.1-1.1-1.1h-1.6v2.2H194.8z" />
                      <path d="M199.3 4.7h-1.5c-0.1 0-0.2-0.1-0.2-0.2V4c0-0.1 0.1-0.2 0.2-0.2h3.9c0.1 0 0.2 0.1 0.2 0.2v0.5c0 0.1-0.1 0.2-0.2 0.2h-1.5 V10c0 0.1-0.1 0.2-0.2 0.2h-0.6c-0.1 0-0.2-0.1-0.2-0.2V4.7z" />
                      <path d="M205.4 4c0-0.1 0.1-0.2 0.2-0.2h2c1.1 0 1.9 0.7 1.9 1.7c0 0.7-0.5 1.2-0.9 1.4c0.5 0.2 1.1 0.6 1.1 1.5c0 1-0.8 1.7-2 1.7 h-2.1c-0.1 0-0.2-0.1-0.2-0.2V4z M207.7 9.3c0.5 0 1-0.4 1-1c0-0.5-0.5-0.9-1.1-0.9h-1.2v1.9H207.7z M207.5 6.6c0.6 0 0.9-0.4 0.9-1 c0-0.5-0.4-0.9-0.9-0.9h-1.2v1.9H207.5z" />
                      <path d="M212.5 7.1l-2.1-3c-0.1-0.1 0-0.3 0.1-0.3h0.7c0.1 0 0.1 0 0.1 0.1l1.6 2.3l1.6-2.3c0 0 0.1-0.1 0.1-0.1h0.7 c0.1 0 0.2 0.1 0.1 0.3l-2.1 3V10c0 0.1-0.1 0.2-0.2 0.2h-0.6c-0.1 0-0.2-0.1-0.2-0.2V7.1z" />
                    </>
                  )}
                </svg>
              </div>
              {/* Conditionally render artist name if exists */}
              {artist && <span css={styles.artist}>{artist}</span>}
            </div>
          </a>
        </div>
      </div>
    );
  }
}
